.rewards {
  min-height: 350px;
  display: flex;
  flex-direction: column;

  & > .title {
    font-size: 18px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    :global(.arco-btn) {
      color: var(--primary-color) !important;
      font-size: 9px;

      &:hover {
        color: var(--primary-dark-color) !important;
      }
    }

    :global(.arco-btn-loading) {
      &::before {
        background-color: transparent !important;
      }
    }
  }

  & > .list {
    margin: 15px 0;

    & > .item {
      height: 56px;
      background-color: var(--block-background-color);
      border-radius: 4px;
      padding: 0 10px;
      margin-bottom: 10px;
      font-weight: 400;

      :global(.arco-btn) {
        padding: 0 5px;
        font-size: 10px !important;
      }

      .status {
        font-size: 12px;
        color: var(--secondary-color);
      }
    }

    :global(.arco-empty) {
      margin-top: 30%;
    }
  }
}

:global(.arco-tooltip-mini) {
  background-color: var(--dialog-background-color);
  font-size: 12px;
}
