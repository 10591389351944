.footer {
  background-color: var(--footer-background-color);
  height: 80px;
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--max-width);

    & > :first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .logo {
        height: 32px;
      }

      & > :last-child {
        margin-left: 20px;
        font-size: 16px;
      }
    }

    & > :last-child {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 42px;
        margin-left: 20px;
      }
    }
  }
}
