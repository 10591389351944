.round {
  background: url(../../assets/bg.png) no-repeat left top;
  background-size: 100% 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    height: 50px;
    display: flex;
    justify-content: center;

    & > div {
      background: url(../../assets/top.png) no-repeat left top;
      background-size: 100% 100%;
      width: 375px;
      height: 100%;
      padding: 0 40px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > :not(:nth-child(2)) {
        padding: 0 0;
        background-color: transparent;

        &:hover {
          background-color: transparent;

          img {
            opacity: 0.8;
          }
        }

        &:disabled {
          img {
            opacity: 0.5;
          }
        }

        & > img {
          width: 30px;
          opacity: 1;
        }
      }

      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > :first-child {
          font-size: 16px;
          color: var(--primary-color);
        }
      }
    }
  }

  .slogan {
    font-size: 20px;
  }

  .info {
    justify-content: center;

    :global(.arco-space) {
      & > :first-child {
        color: var(--primary-color);
        font-size: 18px;
      }

      & > :last-child {
        font-size: 22px;

        & > :last-child {
          span:last-of-type {
            margin-left: 5px;
            font-size: 16px;
          }
        }

        img {
          width: 40px;
        }
      }
    }
  }

  .fee {
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    & > :global(.arco-divider) {
      margin: 0 20px;
    }
  }
}

.payment {
  background-color: var(--block-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  padding: 30px 50px;

  .percent {
    color: var(--secondary-color);
  }

  .selector {
    flex: 1;
    display: flex;
    justify-content: space-between;
    :global(.arco-btn) {
      color: var(--normal-color);
      background-color: var(--fragment-background-color);
      border: 1px solid var(--border-color);
      border-radius: 4px;
      width: 120px;

      &:hover {
        background-color: var(--block-background-color);
        color: var(--normal-color);
      }
    }

    .selected {
      border: solid 1px var(--primary-button-background-color) !important;
    }
  }

  .buy {
    flex: 1;
    display: flex;
    justify-content: center;

    :global(.arco-btn) {
      width: 250px;
      height: 50px;
    }
  }
}

.draw {
  background-color: var(--block-background-color);
  min-height: 396px;
  padding: 10px 10px;
  margin-top: 15px;

  & > .title {
    font-size: 18px;
  }

  .seats {
    margin: 15px 0;
    flex: 1;
    display: flex;
  }

  .reward {
    margin: 15px 0;
    flex: 1;
    display: flex;

    border: 1px solid var(--primary-color);
    padding: 10px 10px;
    border-radius: 5px;
    color: var(--primary-dark-color);

    .status {
      font-size: 12px;
      color: var(--secondary-color);
    }
  }

  .result {
    margin: 15px 0;

    .stat {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      margin-top: 15px;

      :global(.arco-statistic) {
        :global(.arco-statistic-title) {
          color: var(--normal-color);
        }

        :global(.arco-statistic-value) {
          color: var(--primary-color);
          font-size: 18px;

          :global(.arco-statistic-value-decimal) {
            font-size: 14px;
          }
        }

        :global(.arco-statistic-value-suffix) {
          color: var(--primary-dark-color);
          font-size: 10px;
        }
      }
    }

    :global(.arco-row) {
      margin-bottom: 10px;

      &:not(:nth-of-type(1)) {
        color: var(--secondary-color);
      }
    }
  }

  .seat {
    width: 60px;
    height: 30px;
    display: flex;
    background-color: var(--secondary-button-background-color);
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 2px;
  }

  .lucky {
    border: solid 1px var(--primary-button-background-color);
    color: var(--primary-color);
  }

  .empty {
    margin-top: 5%;
  }
}

.helpModal {
  background-color: var(--dialog-background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  top: 200px;

  :global(.arco-modal-header) {
    :global(.arco-modal-title) {
      color: var(--normal-color);
    }
  }

  .wrapper {
    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -65px;
      right: -25px;
      padding: 4px 4px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    .item {
      color: var(--normal-color);
    }

    ul {
      list-style: disc;
    }
  }
}
