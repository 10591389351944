.primaryButton {
  background-color: var(--primary-button-background-color) !important;
  color: var(--normal-dark-color) !important;

  &:hover {
    background-color: var(--primary-button-hover-background-color) !important;
  }
}

.secondaryButton {
  background-color: var(--secondary-button-background-color) !important;
  color: var(--normal-color) !important;

  &:hover {
    background-color: var(--secondary-button-hover-background-color) !important;
  }
}

.warningButton {
  background-color: var(--warning-button-background-color) !important;
  color: var(--normal-color) !important;
  border-width: 0;

  &:hover {
    background-color: var(--warning-button-hover-background-color) !important;
    border-width: 0;
  }
}

.textButton {
  background-color: transparent !important;
  color: var(--normal-color) !important;

  &:hover {
    background-color: transparent !important;
    color: var(--secondary-color) !important;
  }
}

.empty {
  :global(.arco-empty-wrapper) {
    color: var(--secondary-color);
  }

  :global(.arco-empty-description) {
    color: var(--secondary-color);
  }
}
