.seats {
  & > .title {
    font-size: 18px;
  }

  .header {
    margin: 15px 0;
  }

  & > .list {
    height: calc(var(--sider-height) - 100px);
    overflow: scroll;

    & > .item {
      padding: 10px 0;
      font-weight: 400;
      font-size: 12px;

      & > :first-child {
        font-size: 14px;
      }

      & > :last-child {
        font-size: 10px;
        color: var(--secondary-color);
      }
    }

    .badge {
      display: none;
      color: var(--primary-color);
    }

    .lucky {
      a {
        color: var(--primary-color);
      }

      .badge {
        position: absolute;
        top: -12px;
        display: block;
        font-size: 10px;
      }
    }

    :global(.arco-empty) {
      margin-top: 40%;
    }

    .pager {
      margin-top: 10px;
      justify-content: center;

      :global(.arco-pagination-item) {
        color: var(--normal-color);
      }

      :global(.arco-pagination-item:hover) {
        background-color: var(--primary-color);
        color: var(--normal-dark-color);
      }

      :global(.arco-pagination-item-disabled) {
        color: var(--secondary-color);
      }

      :global(.arco-pagination-item-active) {
        background-color: var(--primary-color);
        color: var(--normal-dark-color);
      }
    }
  }

  :global(.arco-divider) {
    margin: 5px 0;
  }
}
