:root {
  --background-color: #171717;
  --primary-color: #afe30f;
  --primary-dark-color: #84af00;
  --normal-color: #fefeff;
  --normal-dark-color: #222;
  --secondary-color: #757575;
  --warning-color: #f53f3f;
  --warning-dark-color: #b90808;
  --divider-color: rgba(255, 255, 255, 0.1);
  --fragment-background-color: #1e1e20;
  --block-background-color: #252525;
  --border-color: #313338;
  --footer-background-color: #171717;
  --dialog-background-color: #1e1e20;
  --loading-color: var(--primary-color);

  --primary-button-background-color: var(--primary-color);
  --primary-button-hover-background-color: var(--primary-dark-color);

  --secondary-button-background-color: #303031;
  --secondary-button-hover-background-color: #3b3b3d;

  --warning-button-background-color: var(--warning-color);
  --warning-button-hover-background-color: var(--warning-dark-color);

  --max-width: 1400px;
  --sider-width: 300px;
  --sider-height: 1000px;
  --main-right-width: 400px;
}

@font-face {
  font-family: 'DIN';
  src: url('../assets/DIN-Alternate-Bold.ttf');
}

body {
  margin: 0;
  font-family: 'DIN', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--normal-color);
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--normal-color);
}

a:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.75);
}

.container {
  min-width: var(--max-width);
  width: 100%;
  height: 100%;

  & > .main {
    width: 100%;
    max-width: var(--max-width);
    align-self: center;
    padding: 20px 0;

    & > .sider {
      background-color: var(--fragment-background-color);
      width: var(--sider-width) !important;
      height: var(--sider-height);
      padding: 10px 10px;
    }

    & > .content {
      margin-left: 20px;
      background-color: var(--fragment-background-color);

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: var(--sider-height);

        & > :first-child {
          flex: 1;
        }

        & > :last-child {
          margin-left: 20px;
          width: var(--main-right-width);
          background-color: var(--fragment-background-color);
          padding: 10px 10px;
        }
      }
    }
  }

  .highlight {
    color: var(--primary-color);

    &:hover[href] {
      color: var(--primary-dark-color);
    }
  }
}

.arco-divider {
  --color-neutral-3: var(--divider-color);
}

.arco-message {
  background-color: var(--dialog-background-color);
  border: 1px solid var(--border-color);

  .arco-message-content {
    color: var(--normal-color);
  }
}

.loadingModal {
  background-color: transparent;
  border-color: transparent;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .arco-spin-icon {
      color: var(--loading-color);
    }

    .arco-spin-dot {
      background-color: var(--loading-color);
    }
  }
}
