.header {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);

  & > .top {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
      max-width: var(--max-width);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 32px;
      }

      :global(.arco-btn) {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        font-weight: 700;
      }
    }
  }

  & > .bottom {
    height: 60px;
    background-color: var(--fragment-background-color);
    display: flex;
    justify-content: center;
    padding: 10px 0;

    & > div {
      width: 100%;
      max-width: var(--max-width);
      height: 100%;
      display: flex;
      align-items: center;

      :global(.arco-space) {
        & > :first-child {
          font-size: 15px;
        }

        & > :last-child {
          font-size: 18px;

          & > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            & > div {
              color: var(--primary-color);
            }

            & > img {
              width: 28px;
              margin-left: 15px;
            }
          }
        }
      }

      :global(.arco-divider) {
        margin: 0 50px;
        height: 60%;
      }
    }
  }
}

:global(.arco-dropdown-menu) {
  background-color: var(--secondary-button-background-color);
  width: 100px;
  padding: 0 0;
  border: 1px solid var(--border-color);

  :global(.arco-dropdown-menu-item) {
    color: var(--normal-color);

    &:hover {
      background-color: var(--secondary-button-hover-background-color);
    }
  }
}

.walletModal {
  background-color: var(--dialog-background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  top: 200px;

  :global(.arco-modal-header) {
    :global(.arco-modal-title) {
      color: var(--normal-color);
    }
  }

  .wrapper {
    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -65px;
      right: -25px;
      padding: 4px 4px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    :global(.arco-menu) {
      background-color: transparent;

      :global(.arco-menu-item) {
        background-color: var(--secondary-button-background-color);
        color: var(--normal-color);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 10px;
        border-radius: 4px;

        &:first-of-type {
          margin-bottom: 10px;
        }

        img {
          width: 34px;
          margin-right: 10px;
        }

        &:hover {
          background-color: var(--secondary-button-hover-background-color);
        }
      }
    }
  }
}
