.contracts {
  & > .title {
    font-size: 18px;
  }

  & > .list {
    margin: 15px 0;
    & > .item {
      background-color: var(--block-background-color);
      border-radius: 4px;
      padding: 10px 10px;
      margin-bottom: 10px;

      & > :last-child {
        font-weight: 400;
        margin-top: 5px;
        font-size: 11px;
      }
    }
  }
}
